.login-background {
  height: 100vh;
  background-image: url("../../img/warehouse.webp");
  background-size: cover;
  background-position: center;
}

@media (max-width: 600px) {
  .login-background {
    background-image: url("../../img/warehouseMobile.webp");
  }
}

.login-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.login-background > * {
  position: relative;
  z-index: 2;
}

.login-card-container {
  margin-top: 20vh;
}
