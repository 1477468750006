.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-container {
  flex: 1;
  min-width: 0;
  background-color: #f0f0f0; /* Example background color */
  border: 1px solid #d9d9d9; /* Example border */
}

.menu-item {
  padding: 10px 20px; /* Example padding */
  font-size: 16px; /* Example font size */
}

.menu-item-selected {
  font-weight: bold; /* Example selected item style */
}

.textDecoration {
  text-decoration: none;
}

/* Horizontal scroll when screen size is less than 500px */
@media (max-width: 600px) {
  .scroll-role-card {
    overflow-x: auto;
  }
}

.ant-card .ant-card-body {
  padding: 12px;
  border-radius: 0 0 8px 8px;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1890ff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0050b3;
  }

  &::-webkit-scrollbar-track {
  }
}

.content-scroll {
  overflow: auto;
  height: calc(90vh - 64px);
  padding: 5px 14px 14px;
}

.ant-layout-header {
  height: 64px;
  padding: 0 25px;
  line-height: 64px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  display: flow;
  align-items: center;
  flex: auto;
  text-align: center;
}

.ant-modal .ant-modal-content {
  padding: 5px;
}
